import * as React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Layout from '../components/layout';
import Topbar from '../components/topbar';
import FeatureCard from '../components/featureCard';

import backgroundFile from '../images/pexels-andrea-piacquadio-3777578.jpg';
import phoneFile from "../images/undraw_audio_player_re_cl20.svg";
import anyProgramFile from "../images/undraw_business_chat_ldig.svg";
import translateFile from "../images/undraw_Around_the_world_re_n353.svg";
import subtitleFile from "../images/undraw_Modern_life_re_8pdp.svg";
import adminFile from "../images/undraw_dashboard_nklg.svg";
import whereFile from "../images/undraw_accept_tasks_po1c.svg";
import puntuctionFile from "../images/undraw_begin_chat_c6pj.svg";
import saveFile from "../images/undraw_Savings_re_eq4w.svg";
import fileFile from "../images/undraw_File_bundle_xl7g.svg";


const photo = {
    border: "0px",
    padding: "5px",
    // width: "20em",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%",
}

// styles
const hero = {
  backgroundImage: `url(${backgroundFile})`,
  height: '50em',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'relative',
};

const claim = {
  color: '#dbdbdb',
  fontSize: '2.5em',
  padding: '22% 2em 0 2em',
  position: 'relative',
};

const tint = {
  content: '',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(255, 255, 255, .5)',
};

const title = {
  padding: '10px',
  color: 'rgb(5, 41, 75)',
};

const description = {

  padding: '10px',
  background: 'rgba(0, 0, 0, .5)',
};

const features = {
  marginTop: "20px"
}

const IndexPage = () => (

  <Layout>
    <Topbar />

    <title>Prealt</title>

    <section style={hero}>
      <div style={tint} />

      <Container>
        <div style={claim}>
          <h1 style={title}>Transcrição de audio para texto</h1>
          <p style={description}>
            Transcrição e tradução simultânea ao vivo. Utilize em suas entrevistas, reuniões, palestras e onde mais desejar.
          </p>
        </div>
      </Container>
    </section>

    <section>
    <Container>
      <Grid container style={features}>

        <FeatureCard
          direction="left"
          title="Transcrição de audio e vídeo"
          desc="Com ajuda da inteligência artificial transforma qualquer audio ou vídeo em textos que podem ser fácilmente encontrados, editados e compartilhados."
          image={
            <img src={phoneFile} alt="trascricao audio video" style={photo} />
          }
        />

        <FeatureCard
          direction="right"
          title="Funciona com qualquer programa"
          desc="Assista vídeos e filmes de qualquer plataforma, faça entrevistas usando Zoom, Microsoft Teams, Google Meeting, etc. Ou transcreva do seu próprio microfone."
          image={
            <img src={anyProgramFile} alt="zoom, microsoft teams, google meet, jitsi" style={photo}/>
          }
        />

        <FeatureCard
          direction="left"
          title="Tradução automática"
          desc="Traduza suas transcrições em tempo real para qualquer lingua."
          image={
            <img src={translateFile} alt="translate, translator" style={photo}/>
          }
        />

        <FeatureCard
          direction="right"
          title="Transcrever arquivos"
          desc="Faça transcrição em lote de seus arquivos de audio e vídeo."
          image={
            <img src={fileFile} alt="files, batch" style={photo}/>
          }
        />

        <FeatureCard
          direction="left"
          title="Legendas automáticas"
          desc="Torne seus vídeos acessíveis colocando legenda para diversas linguas e atinja audiência do mundo inteiro."
          image={
            <img src={subtitleFile} alt="caption, subtitle" style={photo}/>
          }
        />

        <FeatureCard
          direction="right"
          title="Ganhe tempo e economize"
          desc="Transcreva instantaneamente enquanto você está ouvindo."
          image={
            <img src={saveFile} alt="save time, save money" style={photo}/>
          }
        />

        <FeatureCard
          direction="left"
          title="Pontuação automática"
          desc="Ao término da transcrição da sentenção incluimos as pontuações, virgulas e todas as marcações necessárias."
          image={
            <img src={puntuctionFile} alt="puntuction, comma, marks" style={photo}/>
          }
        />

        <FeatureCard
          direction="right"
          title="Administração"
          desc="Utilize o painel de administração para exportar suas transcrições e visualizar o uso dos seus créditos."
          image={
            <img src={adminFile} alt="administration area" style={photo}/>
          }
        />

        <FeatureCard
          direction="left"
          title="Onde usar?"
          desc="Transcrição de audios ou vídeos. Tradução de entrevistas. Geração de legenda. Conversão de mp3 para textos."
          image={
            <img src={whereFile} alt="administration area" style={photo}/>
          }
        />
        
      </Grid>

    </Container>
    </section>

    {/* <div style={heroImagem} />

       <Container>

            <div style={start}>
              asd

            <h1 style={headingStyles}>
              Congratulations
              <br />
              <span style={headingAccentStyles}>— you just made a Gatsby site! </span>
              <span role="img" aria-label="Party popper emojis">
                🎉🎉🎉
              </span>
            </h1>
            <p style={paragraphStyles}>
              Edit <code style={codeStyles}>src/pages/index.js</code> to see this page
              update in real-time.{" "}
              <span role="img" aria-label="Sunglasses smiley emoji">
                😎
              </span>
            </p>
            <ul style={listStyles}>
              <li style={docLinkStyle}>
                <a
                  style={linkStyle}
                  href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
                >
                  {docLink.text}
                </a>
              </li>
              {links.map(link => (
                <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
                  <span>
                    <a
                      style={linkStyle}
                      href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
                    >
                      {link.text}
                    </a>
                    <p style={descriptionStyle}>{link.description}</p>
                  </span>
                </li>
              ))}
            </ul>
            <img
              alt="Gatsby G Logo"
              src="data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2a10 10 0 110 20 10 10 0 010-20zm0 2c-3.73 0-6.86 2.55-7.75 6L14 19.75c3.45-.89 6-4.02 6-7.75h-5.25v1.5h3.45a6.37 6.37 0 01-3.89 4.44L6.06 9.69C7 7.31 9.3 5.63 12 5.63c2.13 0 4 1.04 5.18 2.65l1.23-1.06A7.959 7.959 0 0012 4zm-8 8a8 8 0 008 8c.04 0 .09 0-8-8z' fill='%23639'/%3E%3C/svg%3E"
            />

            </div>

            </Container> */}

  </Layout>
);

export default IndexPage;

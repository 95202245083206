import React from "react"
import Grid from '@material-ui/core/Grid';

import '@fontsource/prompt'; 

const card = {
    display: "block",
    // marginLeft: "auto",
    // marginRight: "auto",
}

const promptFont = {
    fontFamily: "prompt",
    fontSize: "x-large"
}

const featureCard = ({ title, desc, image, direction }) => (
    <>
        { direction === "left" ? (
            <>
                <Grid item xs={6} container
                    direction="row"
                    justify="center"
                    alignItems="center">
                        <div style={card}>
                            <h2 style={promptFont}>{title}</h2>
                            <p>{desc}</p> 
                        </div>
                </Grid>
                <Grid item xs={6}>
                    {image}
                </Grid>
            </>
        ) : (
            <>
                <Grid item xs={6}>
                    {image}
                </Grid>
                <Grid item xs={6} container
                    direction="row"
                    justify="center"
                    alignItems="center">
                        <div style={card}>
                            <h2 style={promptFont}>{title}</h2>
                            <p>{desc}</p> 
                        </div>
                </Grid>
            </>
            )
         }
    </>
)

export default featureCard;